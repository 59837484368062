import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap"
import parse from "html-react-parser"
import $ from "jquery"
import ModalTiny from "react-bootstrap/Modal"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import "../Valuation/ValuationForm/ValuationForm.scss"

import Contact from "../forms/campaign-form"
import ImageCampaign from "../forms/campaign-form-image-upload"
import TrustPilotWidget from "../TrustPilotWidget"

import Trustpilot from "../../images/trustpilot.png"
import GoogleReviews from "../../images/google-reviews.png"
import GoogleReviewCount from "../GoogleReviewCount/GoogleReviewCount"

const CampaignLanding = (props) => {

  // console.log("aaa",props.Add_Select_Options.Add_Options);

  var items = []
  var type_options = ''
  if (props.Add_Select_Options?.Add_Options) {
    props.Add_Select_Options.Add_Options.map((item, index) => {
      items += item.Option + ","
    })
    if (items.length > 0) {
      type_options = items.replace(/,\s*$/, "").split(',')
    }
  }



  return (
    <React.Fragment>
      <section className="valuation-page" style={{ background: "url(" + props.Background_Banner.url + ")" }}>
        <div className="page">
          <Container>
            <Row>
              <Col md='12' className="heading " lg={7}>
                {props.Banner_Sub_Title &&
                  <span class="sm-text">{props.Banner_Sub_Title}</span>
                }
                {props.Banner_Content ? parse(props.Banner_Content) : ''}
              </Col>
            </Row>
            <Row>
              <Col md='12' lg={7}>
                <div className="terms-text">*Terms & conditions apply</div>
                <div className="valuation-form contact-main-form">
                  <div className="main-calc">
                    <div className="form-wrapper">
                      {props.Image_Upload ?
                      <ImageCampaign {...props} type_options={type_options} /> : <Contact {...props} type_options={type_options} /> }
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="d-none d-lg-block">

              </Col>
              <div className="main-banner">
                <div className="rating-block">
                  <div className='trustpilot-review'>
                    <TrustPilotWidget />
                  </div>

                  <div className='google-review'>
                    <GoogleReviewCount />
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>

      </section>
    </React.Fragment >
  )
}

export default CampaignLanding